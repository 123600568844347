'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from '@edeeone/juan-core/components/button/Button';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { useFormState } from 'react-hook-form';
import { useFormStatus } from './Form';
export const SubmitButton = ({ design: designProp, label: labelProp, icon: iconProp, i18nNamespace, successLabel, successDesign, successIcon, pendingLabel: pendingLabelProp, enableSuccess = false, enablePending = false, ...props }) => {
    const i18nNamespaceFinal = `Form${i18nNamespace ? `.${i18nNamespace}` : ''}`;
    const { t } = useI18n(i18nNamespaceFinal);
    // NOTE: isLoading is for loading async default values, isSubmitting is for form submission
    const { isLoading, isSubmitting } = useFormState();
    const { isLoading: isLoadingStatus, successStatus: { success }, } = useFormStatus();
    const showSuccess = enableSuccess && success;
    const isPending = isLoading || isLoadingStatus || isSubmitting;
    // Labels Section
    const noPendingLabel = showSuccess ? successLabel : labelProp || t('submit');
    const pendingLabel = !enablePending
        ? noPendingLabel
        : pendingLabelProp || t('pending');
    const label = isPending ? pendingLabel : noPendingLabel;
    // Design
    const design = showSuccess ? successDesign : designProp;
    // Icon
    const icon = showSuccess ? successIcon : iconProp;
    return (_jsx(Button, { ...props, design: design, type: "submit", loading: isLoading || isLoadingStatus || isSubmitting, disabled: enableSuccess ? success : undefined, icon: icon, children: label }));
};
