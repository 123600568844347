'use client';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { selectBoxClassNames } from '@edeeone/juan-core/components/form/selectBox/selectBox.styles';
import { Modal } from '@edeeone/juan-core/components/modal/Modal';
import { SvgSpriteIcon } from '@edeeone/juan-core/components/svgSpriteIcon/SvgSpriteIcon';
import { useBreakpoints } from '@edeeone/juan-core/hooks/useBreakpoints';
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import { Listbox } from '@headlessui/react';
import { useField, } from '@edeeone/juan-core/components/form/useField';
const itemTemplate = (item, finalStyles) => {
    return (_jsx(Listbox.Option, { value: item.value, disabled: item.disabled, children: ({ selected, disabled }) => {
            return (_jsxs("div", { className: twMergeJuan(finalStyles.item, selected && finalStyles.item__selected, disabled && finalStyles.item__disabled), children: [item.itemLabelPrefix && (_jsx("span", { className: finalStyles.itemLabelPrefix, children: item.itemLabelPrefix })), _jsxs("span", { className: twMergeJuan(finalStyles.itemLabel, disabled && finalStyles.itemLabelDisabled, item.visualDisabled && finalStyles.itemLabelDisabled), children: [item.label, ' ', selected && (_jsx(SvgSpriteIcon, { icon: "formCheck", className: finalStyles.itemIcon }))] }), (!!item.itemLabelSuffix || !!item.itemLabelSuffixLink) && (_jsxs("span", { className: finalStyles.itemLabelSuffix, children: [item.itemLabelSuffix, item.itemLabelSuffixLink] }))] }));
        } }, item.value));
};
export const SelectBox = ({ placeholder, items = [], styles, showDropdownOnSmallDevice, initialValue, ...props }) => {
    const { breakpoints } = useBreakpoints();
    const isMobile = !breakpoints?.includes('md');
    const finalStyles = selectBoxClassNames(styles, 'primary', undefined, {
        showDropdownOnSmallDevice,
    });
    const { onChange, error, value, label, disabled } = useField({
        undefinedValue: initialValue,
        ...props,
    });
    if (showDropdownOnSmallDevice && isMobile) {
        return (_jsx("div", { className: finalStyles.container, children: _jsx(Listbox, { disabled: disabled, value: value, onChange: onChange, children: ({ open }) => {
                    return (_jsxs(_Fragment, { children: [_jsxs(Listbox.Button, { className: twMergeJuan(finalStyles.input, open && finalStyles.input__open, !!error && finalStyles.input__error), children: [items.find((item) => {
                                        return item.value == value;
                                    })?.label || placeholder, _jsx(SvgSpriteIcon, { className: twMergeJuan(finalStyles.input_ico, open && finalStyles.input_ico__open), icon: "arrow" })] }), _jsx(Modal, { design: "secondary", isOpen: open, title: label, children: _jsx(Listbox.Options, { static: true, children: items.map((item) => {
                                        return itemTemplate(item, finalStyles);
                                    }) }) })] }));
                } }) }));
    }
    return (_jsx("div", { className: finalStyles.container, children: _jsx(Listbox, { disabled: disabled, value: value, onChange: onChange, children: ({ open }) => {
                return (_jsxs(_Fragment, { children: [_jsxs(Listbox.Button, { className: twMergeJuan(finalStyles.input, open && finalStyles.input__open, !!error && finalStyles.input__error), children: [items.find((item) => {
                                    return item.value == value;
                                })?.label || placeholder, _jsx(SvgSpriteIcon, { className: twMergeJuan(finalStyles.input_ico, open && finalStyles.input_ico__open), icon: "arrow" })] }), _jsx(Listbox.Options, { className: finalStyles.itemsContainer, children: items.map((item) => {
                                return itemTemplate(item, finalStyles);
                            }) })] }));
            } }) }));
};
